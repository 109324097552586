import { Box } from '@mui/material'
import lukaLogo from 'assets/logos/lukaBusinessWhite.svg'
import React from 'react'
import { Colors } from 'theme/colors'
import styles from 'styles/global.module.css'
import Credentials from './Credentials'
import NavComponent from './NavComponent'

const Sidebar = () => {
  const AllColors = new Colors()
  return (
    <Box 
      component={'aside'}
      sx={
        {
          height: '100%',
          width: '250px',
          flexDirection: 'column',
          justifyContent: 'space-between',
          alignItems: 'center',
          backgroundColor: AllColors.darkBlue,
          display: {
            smallMobile: 'none',
            web: 'flex'
          }
        }
      }
    >
      <Box 
        component={'figure'} 
        sx={
          {
            width: '115px'
          }
        }
      >
        <img 
          className={styles.image} 
          src={lukaLogo} 
          alt="Logo Luka" 
          style={{paddingTop: '40px'}}
        />
      </Box>
      <NavComponent type={'web'} />
      <Credentials />
    </Box>
  )
}

export default Sidebar