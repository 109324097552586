import ReactDOM from 'react-dom';
import { Box } from '@mui/material'
import React from 'react'
import { Colors } from 'theme/colors'

const PopUpModal = ({children}) => {
    const AllColors = new Colors()
    return ReactDOM.createPortal(
        <Box
            component={'div'}
            sx={
                {
                    backgroundColor: '#20232993',
                    position: 'fixed',
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    zIndex: 10,
                }
            }
        >
            <Box 
                component={'div'}
                sx={
                    {
                        backgroundColor: AllColors.white,
                        width: '100%',
                        maxWidth: '400px',
                        height: '300px',
                        borderRadius: '8px',
                        padding: '30px 20px',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                    }
                }
            >
                {children}
            </Box>
        </Box>,
        document.getElementById('modal')
    )
}

export default PopUpModal