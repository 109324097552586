import React, { useEffect } from 'react'
import brandImg from 'assets/icons/buildings.svg';
import branchesImg from 'assets/icons/shop.svg';
import servicesImg from 'assets/icons/broom.svg';
import productImg from 'assets/icons/bag.svg'
import resultsImg from 'assets/icons/tick-circle.svg';
import { useLocation, useNavigate } from 'react-router-dom'
import NavBox from './NavBox';
import { Box } from '@mui/material';
import { Colors } from 'theme/colors';
import navboxStyles from './NavBox/navbox.module.css'
import './index.css'

/**
 * Navigation image to go to different views
 * @param  {string} type "web" or "mobile"
 */
const NavComponent = ({type}) => {
  const AllColors = new Colors()
  const location = useLocation()
  const navigate = useNavigate()

  /**
   * Handles routing to different views
   * @param  {string} pathname path to a different view
   */
  const onClick = (pathname) => {
      navigate(pathname)
  }

  /**
   * Handles which element should be active (in another color and with another behavior)
   */
  const activeElement = () => {
    const activeInfo = Array.from(document.getElementsByClassName('active-nav'))
    const active = activeInfo.filter((item) => 
        item.id === `brand-nav-${type}` ||  `branch-nav-${type}` || `service-nav-${type}`)
    switch (location.pathname) {
        case "/brand":
            if (active.length > 0) {
                active.forEach((info) => {
                    info.classList.remove('active-nav')
                    info.classList.add(navboxStyles['menu-nav-disabled'])
                })
            }
            document.getElementById(`brand-nav-${type}`).classList.add('active-nav')
            document.getElementById(`prog-state-${type}`).style.height = '0%'
            break;
        case "/branch":
            if (active.length > 0) {
                active.forEach((info) => {
                    info.classList.remove('active-nav')
                    info.classList.add(navboxStyles['menu-nav-disabled'])
                })
            }
            document.getElementById(`brand-nav-${type}`).classList.add('active-nav')
            document.getElementById(`branch-nav-${type}`).classList.add('active-nav')
            document.getElementById(`brand-nav-${type}`).classList.remove(navboxStyles['menu-nav-disabled'])
            document.getElementById(`prog-state-${type}`).style.height = '25%'
            break;
        case "/service":
            if (active.length > 0) {
                active.forEach((info) => {
                    info.classList.remove('active-nav')
                    info.classList.add(navboxStyles['menu-nav-disabled'])
                })
            }
            document.getElementById(`brand-nav-${type}`).classList.add('active-nav')
            document.getElementById(`branch-nav-${type}`).classList.add('active-nav')
            document.getElementById(`service-nav-${type}`).classList.add('active-nav')
            document.getElementById(`brand-nav-${type}`).classList.remove(navboxStyles['menu-nav-disabled'])
            document.getElementById(`branch-nav-${type}`).classList.remove(navboxStyles['menu-nav-disabled'])
            document.getElementById(`prog-state-${type}`).style.height = '50%'
            break;
        case "/product":
            if (active.length > 0) {
                active.forEach((info) => {
                    info.classList.remove('active-nav')
                    info.classList.add(navboxStyles['menu-nav-disabled'])
                })
            }
            document.getElementById(`brand-nav-${type}`).classList.add('active-nav')
            document.getElementById(`branch-nav-${type}`).classList.add('active-nav')
            document.getElementById(`service-nav-${type}`).classList.add('active-nav')
            document.getElementById(`product-nav-${type}`).classList.add('active-nav')
            document.getElementById(`brand-nav-${type}`).classList.remove(navboxStyles['menu-nav-disabled'])
            document.getElementById(`branch-nav-${type}`).classList.remove(navboxStyles['menu-nav-disabled'])
            document.getElementById(`service-nav-${type}`).classList.remove(navboxStyles['menu-nav-disabled'])
            document.getElementById(`prog-state-${type}`).style.height = '75%'
            break;
        case "/result":
            if (active.length > 0) {
                active.forEach((info) => {
                    info.classList.remove('active-nav')
                    info.classList.add(navboxStyles['menu-nav-disabled'])
                })
            }
            document.getElementById(`brand-nav-${type}`).classList.add('active-nav')
            document.getElementById(`branch-nav-${type}`).classList.add('active-nav')
            document.getElementById(`service-nav-${type}`).classList.add('active-nav')
            document.getElementById(`product-nav-${type}`).classList.add('active-nav')
            document.getElementById(`results-nav-${type}`).classList.add('active-nav')
            document.getElementById(`brand-nav-${type}`).classList.remove(navboxStyles['menu-nav-disabled'])
            document.getElementById(`branch-nav-${type}`).classList.remove(navboxStyles['menu-nav-disabled'])
            document.getElementById(`service-nav-${type}`).classList.remove(navboxStyles['menu-nav-disabled'])
            document.getElementById(`product-nav-${type}`).classList.remove(navboxStyles['menu-nav-disabled'])
            document.getElementById(`prog-state-${type}`).style.height = '100%'
            break;
        default:
            if (active.length > 0) {
                active.forEach((info) => {
                    info.classList.remove('active-nav')
                    info.classList.add(navboxStyles['menu-nav-disabled'])
                })
            }
            document.getElementById(`prog-state-${type}`).style.height = '0%'
            break;
    }
  }

  /**
   * Executes activeElement each time the user changes from one view to another
   * @param  {string} location path of current view
   */
  useEffect(() => {
      activeElement()
  }, [location])
  return (
      <Box 
        className={'menu-nav-container'}
        component={'nav'}
        sx={
          {
            width: '180px',
            height: '40%',
            maxHeight: '400px',
            minHeight: '250px',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            position: 'relative',
          }
        }
      >
          <Box 
            className='prog'
            component={'div'}
            sx={
              {
                position: 'absolute',
                minHeight: 'inherit',
                width: '3px',
                backgroundColor: AllColors.background,
                zIndex: 1,
                left: '19px',
                height: '100%'
              }
            }
          >
              <Box 
                id={`prog-state-${type}`}
                component={'div'}
                sx={
                  {
                    backgroundColor: AllColors.aquaBlue,
                    width: '100%',
                    transition: '0.3s all',
                  }
                }
              >
              </Box>
          </Box>
          <NavBox 
            id={`brand-nav-${type}`} 
            text={'Marca'} 
            img={brandImg} 
            onClick={() => onClick('/brand')} 
          />
          <NavBox 
            id={`branch-nav-${type}`} 
            text={'Sucursales'} 
            img={branchesImg} 
            onClick={() => onClick('/branch')} 
          />
          <NavBox 
            id={`service-nav-${type}`} 
            text={'Servicios'} 
            img={servicesImg} 
            onClick={() => onClick('/service')} 
          />
          <NavBox 
            id={`product-nav-${type}`} 
            text={'Productos'} 
            img={productImg} 
            onClick={() => onClick('/product')} 
          />
          <NavBox 
            id={`results-nav-${type}`} 
            text={'Confirmación'} 
            img={resultsImg} 
            onClick={() => onClick('/branch')} 
          />
      </Box>
  )
}

export default NavComponent