import { Box, Typography } from '@mui/material'
import ModalButton from 'components/Buttons/ModalButton'
import { useUi } from 'hooks/useUi'
import React from 'react'
import { useSelector } from 'react-redux'
import errorImg from 'assets/img/error.svg'
import globalStyles from 'styles/global.module.css'

const ErrorBox = () => {
    const ui = useSelector(state => state.local.ui)
    const uiHook = useUi()
    const handleClick = () => {
        uiHook.handleResetError()
    }
    return (
        <React.Fragment>
            <Box
                component={'figure'}
                className={globalStyles['flex-row-vert-hor-center']}
                sx={
                    {
                        borderTopLeftRadius: '8px',
                        borderTopRightRadius: '8px'
                    }
                }
            >
                <img src={errorImg} alt={'Error'} />
            </Box>
            <Box>
                <Typography
                    variant='h3'
                >
                    {ui.error.message}
                </Typography>
                <Typography
                    variant='h3'
                >
                    Por favor, intente de nuevo
                </Typography>
            </Box>
            <Box>
                <ModalButton
                    handleClickCancel={handleClick}
                    accept={false}
                    text={'Aceptar'}
                />
            </Box>
        </React.Fragment>
    )
}

export default ErrorBox