import { Box } from '@mui/material'
import React from 'react'
import CredentialBox from './CredentialBox'
import account from 'assets/icons/account.svg'
import logout from 'assets/icons/logout.svg'
import { useSelector } from 'react-redux'
import { useService } from 'hooks/useService'
import { useBrand } from 'hooks/useBrand'
import { useBranch } from 'hooks/useBranch'
import { useNavigate } from 'react-router-dom'

const Credentials = () => {
    const user = useSelector(state => state.session.username)
    const brand = useBrand()
    const branch = useBranch()
    const service = useService()
    const navigate = useNavigate()
    const handleClick = () => {
        navigate('/')
        service.handleResetState()
        branch.handleResetState()
        brand.handleResetState()
    }
    return (
        <Box
            component={'div'}
            sx={
                {
                    width: '100%',
                    padding: '0 25px 25px 10px'
                }
            }
        >
            <CredentialBox img={account} text={user ? user : 'Invitado'} onClick={() => console.log('User')}/>
            <CredentialBox img={logout} text={'Salir'} onClick={handleClick} />
        </Box>
    )
}

export default Credentials