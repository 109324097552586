import React, { Suspense } from "react";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import MainLayout from "layout/MainLayout";
import Tips from "pages/Tips";
import { useSelector } from "react-redux";
import PopUpModal from "components/Modals/PopUpModal";
import ErrorBox from "components/Modals/PopUpModal/ErrorBox";
import ProtectedRoute from "components/ProtectedRoute";
import { Box } from "@mui/material";
import LoadingIcon from "components/Loading";
import { Colors } from "theme/colors";
import { useBrand } from "hooks/useBrand";
import { useProduct } from "hooks/useProduct";

const BrandView = React.lazy(() =>  import('pages/Brand'))
const BranchView = React.lazy(() => import('pages/Branch'))
const ServiceView = React.lazy(() => import('pages/Service'))
const ProductView = React.lazy(() => import('pages/Product'))
const ResultView = React.lazy(() => import('pages/Result'))

/**
 * App component with all routes
 */
function AppRoutes() {
  const AllColors = new Colors()
  const ui = useSelector(state => state.local.ui)
  const brand = useSelector(state => state.local.brand)
  const product = useSelector(state => state.local.product)
  const brandHook = useBrand()
  const productHook = useProduct()
  return (
    <React.Fragment>
      <BrowserRouter>
        <MainLayout>
          <Routes>
            <Route 
              path={"/"} 
              element={<Tips />} 
            />
            <Route 
              path={"/brand"} 
              element={
                <ProtectedRoute>
                  <Suspense 
                    fallback={
                      <Box
                        sx={
                          {
                            height: '100%',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center'
                          }
                        }
                      >
                        <LoadingIcon 
                          size={7} 
                          border={0.6} 
                          firstColor={AllColors.primary}
                          secondColor={AllColors.primary}
                        />
                      </Box>
                    }
                  >
                    <BrandView />
                  </Suspense>
                </ProtectedRoute>
              } 
            />
            <Route 
              path={"/branch"} 
              element={
                brandHook.verifyBrand(brand)
                  ?
                  <ProtectedRoute>
                    <Suspense 
                      fallback={
                        <Box
                          sx={
                            {
                              height: '100%',
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'center'
                            }
                          }
                        >
                          <LoadingIcon 
                            size={7} 
                            border={0.6} 
                            firstColor={AllColors.primary}
                            secondColor={AllColors.primary}
                          />
                        </Box>
                      }
                    >
                      <BranchView />
                    </Suspense>
                  </ProtectedRoute>
                  :
                  <Navigate to={'/'} />
              } 
            />
            <Route 
              path={"/service"} 
              element={
                brandHook.verifyBranch(brand)
                  ?
                  <ProtectedRoute>
                    <Suspense 
                      fallback={
                        <Box
                          sx={
                            {
                              height: '100%',
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'center'
                            }
                          }
                        >
                          <LoadingIcon 
                            size={7} 
                            border={0.6} 
                            firstColor={AllColors.primary}
                            secondColor={AllColors.primary}
                          />
                        </Box>
                      }
                    >
                      <ServiceView />
                    </Suspense>
                  </ProtectedRoute>
                  :
                  <Navigate to={'/'} />
              } 
            />
            <Route 
              path={"/product"} 
              element={
                brandHook.verifyService(brand)
                  ?
                  <ProtectedRoute>
                    <Suspense 
                      fallback={
                        <Box
                          sx={
                            {
                              height: '100%',
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'center'
                            }
                          }
                        >
                          <LoadingIcon 
                            size={7} 
                            border={0.6} 
                            firstColor={AllColors.primary}
                            secondColor={AllColors.primary}
                          />
                        </Box>
                      }
                    >
                      <ProductView />
                    </Suspense>
                  </ProtectedRoute>
                  :
                  <Navigate to={'/'} />
              } 
            />
            <Route 
              path={"/result"} 
              element={
                productHook.verifyInfo(product)
                  ?
                  <ProtectedRoute>
                    <Suspense 
                      fallback={
                        <Box
                          sx={
                            {
                              height: '100%',
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'center'
                            }
                          }
                        >
                          <LoadingIcon 
                            size={7} 
                            border={0.6} 
                            firstColor={AllColors.primary}
                            secondColor={AllColors.primary}
                          />
                        </Box>
                      }
                    >
                      <ResultView />
                    </Suspense>
                  </ProtectedRoute>
                  :
                  <Navigate to={'/'} />
              } 
            />
          </Routes>
        </MainLayout>
      </BrowserRouter>
      {
        ui.error.type
          &&
          <PopUpModal>
            <ErrorBox />
          </PopUpModal>
      }
    </React.Fragment>
  );
}

export default AppRoutes;