import { useDispatch } from 'react-redux'
import { 
    setId, 
    setName, 
    setDescription, 
    setReference,
    setCurrency,
    setPayment,
    deleteReference,
    setFullService, 
    resetState, 
} from '../redux/slices/serviceSlice'

/**
 * Hook that handles all state changes for a service, and functions to verify information and 
 * validate inputs
 */
const useService = () => {
    const dispatch = useDispatch();

    /**
     * All functions to handle state changes
     */
    const handleIdChange = () => {
        dispatch(setId())
    }
    const handleNameChange = (value) => {
        dispatch(setName(value))
    }
    const handleDescriptionChange = (value) => {
        dispatch(setDescription(value))
    }
    const handleReferenceChange = (value) => {
        dispatch(setReference(value))
    }
    const handleCurrencyChange = (value) => {
        dispatch(setCurrency(value))
    }
    const handlePaymentChange = (value) => {
        dispatch(setPayment(value))
    }
    const handleDeleteReference = (value) => {
        dispatch(deleteReference(value))
    }
    const handleFullService = (value) => {
        dispatch(setFullService(value))
    }
    const handleResetState = (value) => {
        dispatch(resetState())
        if (value) {
            dispatch(setCurrency(value))
        }
    }
    
    /**
     * Verifys if the service state is full
     * @param {Object} service Object with a service structure
     * @return {boolean}
     */
    const verifyInfo = (service) => {
        if (
            service.name &&
            service.description &&
            service.payment.length > 0
            ) {
                return true;
        }
        return false;
    }
        
    /**
     * Validate if all inputs for a service are not empty
     * @param {Object} service Object with a service structure
     * @return {Object}
     */
    const validateInputs = (service) => {
        const serviceObject = {}
        serviceObject.name = service.name ? "" : "Requerido"
        serviceObject.description = service.description ? "" : "Requerido"
        serviceObject.currency = service.currency.length > 0 ? "" : "Requerido"
        serviceObject.payment = service.payment.length > 0 ? "" : "Requerido"

        return serviceObject;
    }

    return {
        handleIdChange,
        handleNameChange,
        handleDescriptionChange,
        handleReferenceChange,
        handleDeleteReference,
        handleCurrencyChange,
        handlePaymentChange,
        handleFullService,
        handleResetState,
        verifyInfo,
        validateInputs
    }
}
export {useService};