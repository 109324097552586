import { useDispatch } from 'react-redux'
import { 
    setId,
    setName, 
    setCountryName,
    setCountryCode,
    setCategory, 
    setSubCategory, 
    setLogo, 
    setIsotypeLogo,
    setBranch,
    deleteBranch,
    setServiceInBranch,
    massiveAddService,
    deleteServiceInBranch,
    resetState,
    massiveAddProductInService,
} from 'redux/slices/brandSlice';

/**
 * Hook that handles all state changes for a brand, and functions to verify information and 
 * validate inputs
 */
const useBrand = () => {
    const dispatch = useDispatch();

    /**
     * All functions to handle state changes
     */
    const handleIdChange = () => {
        dispatch(setId())
    }
    const handleNameChange = (value) => {
        dispatch(setName(value))
    }
    const handleCountryNameChange = (value) => {
        dispatch(setCountryName(value))
    }
    const handleCountryCodeChange = (value) => {
        dispatch(setCountryCode(value))
    }
    const handleCategoryChange = (value) => {
        dispatch(setCategory(value))
    }
    const handleSubCategoryChange = (value) => {
        dispatch(setSubCategory(value))
    }
    const handleLogoChange = (value) => {
        dispatch(setLogo(value))
    }
    const handleIsoLogoChange = (value) => {
        dispatch(setIsotypeLogo(value))
    }
    const handleBranchChange = (value) => {
        dispatch(setBranch(value))
    }
    const handleDeleteBranch = (value) => {
        dispatch(deleteBranch(value))
    }
    const handleNewService = (value) => {
        dispatch(setServiceInBranch(value))
    }
    const handleMassiveServices = (value) => {
        dispatch(massiveAddService(value))
    }
    const handleDeleteServiceInBranch = (value) => {
        dispatch(deleteServiceInBranch(value))
    }
    const handleMassiveProduct = (value) => {
        dispatch(massiveAddProductInService(value))
    }
    const handleResetState = () => {
        dispatch(resetState())
    }
    
    /**
     * Verifys if the brand state is full
     * @param {Object} brand Object with a brand structure
     * @return {boolean}
     */
    const verifyBrand = (brand) => {
        if (
            brand.id !== '' &&
            brand.name !== '' && 
            brand.category !== '' && 
            brand.subCategory !== '' && 
            brand.logo.url !== '' &&
            brand.isotypeLogo.url !== '' 
            ) {
                return true;
        }
        return false;
    }
        
    /**
     * Validate if all inputs for a brand are not empty
     * @param {Object} brand Object with a brand structure
     * @return {Object}
     */
    const validateInputs = (brand) => {
        const brandObject = {}
        brandObject.name = brand.name ? "" : "Requerido"
        brandObject.country = brand.country.name ? "" : "Requerido"
        brandObject.category = brand.category ? "" : "Requerido"
        brandObject.subCategory = brand.subCategory ? "" : "Requerido"
        brandObject.logo = brand.logo.url ? "" : "Requerido"
        brandObject.isotypeLogo = brand.isotypeLogo.url ? "" : "Requerido"

        return brandObject;
    }

    /**
     * Verifys if the brand state has, at least, one branch
     * @param {Object} brand Object with a brand structure
     * @return {boolean}
     */
    const verifyBranch = (brand) => {
        if (brand.branches.length > 0) {
                return true;
        }
        return false;
    }

    /**
     * Verifys if all branches associated to a brand has, at least, one service
     * @param {Object} brand Object with a brand structure
     * @return {boolean}
     */
    const verifyService = (brand) => {
        let verify = true
        if (!verifyBranch(brand)) {
            return false
        }
        for (let index = 0; index < brand.branches.length; index++) {
            if (brand.branches[index].services.length > 0) {
                for (let serviceIndex = 0; serviceIndex < brand.branches[index].services.length; serviceIndex++ ) {
                    if (brand.branches[index].services[serviceIndex].currency.length > 0) {
                        if (brand.branches[index].services[serviceIndex].payment.length > 0) {
                            continue
                        } else {
                            verify = false
                            break
                        }
                    } else {
                        verify = false
                        break
                    }
                }
            } else {
                verify = false
                break
            }
        }
        return verify
    }

    /**
     * Verifys if all branches associated to a brand has, at least, one service and each
     * service has, at least, one product
     * @param {Object} brand Object with a brand structure
     * @return {boolean}
     */
    const verifyProduct = (brand) => {
        let verify = true
        let linkVerify = true
        let gatewayVerify = true
        if (!verifyBranch(brand)) {
            return false
        }
        if (!verifyService(brand)) {
            return false
        }
        if (Object.keys(brand.branches[0].services[0].products).length > 0) {
            if (brand.branches[0].services[0].products.link) {
                if (!brand.branches[0].services[0].products.link.add) {
                    linkVerify = false
                }
            } else {
                linkVerify = false
            }
        } else {
            linkVerify = false
        }
        if (Object.keys(brand.branches[0].services[0].products).length > 0) {
            if (brand.branches[0].services[0].products.gateway) {
                if (!brand.branches[0].services[0].products.gateway.add) {
                    gatewayVerify = false
                }
            } else {
                gatewayVerify = false
            }
        } else {
            gatewayVerify = false
        }
        if (!linkVerify && !gatewayVerify) {
            verify = false
        }
        return verify
    }

    return {
        handleIdChange,
        handleNameChange,
        handleCountryNameChange,
        handleCountryCodeChange,
        handleCategoryChange,
        handleSubCategoryChange,
        handleLogoChange,
        handleIsoLogoChange,
        handleBranchChange,
        handleDeleteBranch,
        handleNewService,
        handleMassiveServices,
        handleDeleteServiceInBranch,
        handleMassiveProduct,
        handleResetState,
        verifyBrand,
        validateInputs,
        verifyBranch,
        verifyService,
        verifyProduct
    }
}
export {useBrand};


