import { Box, Typography } from '@mui/material'
import React from 'react'
import { Colors } from 'theme/colors'

const TipsBox = ({img, text}) => {
    const AllColors = new Colors()
    return (
        <Box
            component={'div'}
            sx={
                {
                    height: '185px',
                    width: '300px',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    padding: '15px',
                    backgroundColor: AllColors.background,
                    borderRadius: '8px'
                }
            }
        >
            <Box
                component={'figure'}
                sx={
                    {
                        height: '90px',
                        width: '90px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        marginBottom: '5px'
                    }
                }
            >
                <img src={img} alt={'Tips'} style={{maxWidth: '100%', maxHeight: '100%'}} />
            </Box>
            <Box
                sx={{textAlign: 'center'}}
            >
                <Typography variant='paragraph'>
                    {text}
                </Typography>
            </Box>
        </Box>
    )
}

export default TipsBox