import { Box, Typography } from '@mui/material'
import React, { useEffect } from 'react'
import { Colors } from 'theme/colors'
import progressStyles from './progressicon.module.css'
import { useSelector } from 'react-redux'
import { useBrand } from 'hooks/useBrand'
import { useProduct } from 'hooks/useProduct'

const ProgressIcon = () => {
    const AllColors = new Colors()
    const brand = useSelector(state => state.local.brand)
    const product = useSelector(state => state.local.product)
    const brandHook = useBrand()
    const productHook = useProduct()
    useEffect(() => {
        if (brandHook.verifyBrand(brand)) {
            document.getElementById('progress-1').classList.add(progressStyles['blue-progress'])
            if (brandHook.verifyBranch(brand)) {
                document.getElementById('progress-2').classList.add(progressStyles['blue-progress'])
                if (brandHook.verifyService(brand)) {
                    document.getElementById('progress-3').classList.add(progressStyles['blue-progress'])
                    if (productHook.verifyInfo(product)) {
                        document.getElementById('progress-4').classList.add(progressStyles['blue-progress'])
                    } else {
                        document.getElementById('progress-4').classList.remove(progressStyles['blue-progress'])
                    }
                } else {
                    document.getElementById('progress-3').classList.remove(progressStyles['blue-progress'])
                }
            } else {
                document.getElementById('progress-2').classList.remove(progressStyles['blue-progress'])
            }
        } else {
            document.getElementById('progress-1').classList.remove(progressStyles['blue-progress'])
        }
    }, [brand])
    return (
        <Box
            component={'div'}
            sx={
                {
                    height: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'flex-end',
                }
            }
        >
            <Box
                component={'div'}
                sx={
                    {
                        height: '40px',
                        width: '54px',
                        display: 'flex',
                        justifyContent: 'center'
                    }
                }
            >
                <Box
                    component={'div'}
                    id={'progress-1'}
                    sx={
                        {
                            width: '8px',
                            height: '100%',
                            backgroundColor: AllColors.principalGray,
                            borderRadius: '8px',
                            marginRight: '3px'
                        }
                    }
                ></Box>
                <Box
                    component={'div'}
                    id={'progress-2'}
                    sx={
                        {
                            width: '7.5px',
                            height: '100%',
                            backgroundColor: AllColors.principalGray,
                            borderRadius: '8px',
                            margin: '0 3px'
                        }
                    }
                ></Box>
                <Box
                    component={'div'}
                    id={'progress-3'}
                    sx={
                        {
                            width: '7.5px',
                            height: '100%',
                            backgroundColor: AllColors.principalGray,
                            borderRadius: '8px',
                            margin: '0 3px'
                        }
                    }
                ></Box>
                <Box
                    component={'div'}
                    id={'progress-4'}
                    sx={
                        {
                            width: '7.5px',
                            height: '100%',
                            backgroundColor: AllColors.principalGray,
                            borderRadius: '8px',
                            margin: '0 3px'
                        }
                    }
                ></Box>
            </Box>
            <Typography
                variant='h3'
                sx={
                    {
                        fontSize: '13px !important',
                        color: AllColors.principalGray
                    }
                }
            >
                progreso
            </Typography>
        </Box>
    )
}

export default ProgressIcon