import { Box, Typography } from '@mui/material'
import MainButton from 'components/Buttons/MainButton'
import React, { useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import MenuIcon from '@mui/icons-material/Menu';
import ProgressIcon from './ProgressIcon'
import tipsIcon from 'assets/icons/tips.svg'
import { Colors } from 'theme/colors';
import SidebarModal from 'components/Modals/SidebarModal';
import { useUi } from 'hooks/useUi';
import { useSelector } from 'react-redux';

const Header = ({onClick, disabled=false}) => {
  const [ mobileMenu, setMobileMenu ] = useState(false)
  const user = useSelector(state => state.session)
  const AllColors = new Colors()
  const location = useLocation().pathname
  const navigate = useNavigate()
  const uiHook = useUi()
  const getText = (param) => {
    switch (param) {
      case '/brand':
        return 'Marca'
      case '/branch':
        return 'Sucursales'
      case '/service':
        return 'Servicios'
      case '/product':
        return 'Productos'
      case '/result':
        return 'Resultados'
      default:
        return ''
    }
  }
  return (
    <React.Fragment>
      <Box
          component={'header'}
          sx={
              {
                  width: '100%',
                  height: '125px',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between'
              }
          }
      >
          <Box
            component={'div'}
            sx={
                {
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    marginLeft: {
                          smallMobile: '40px',
                          tablet: '60px',
                    }
                }
            }
          >
            <Box
              component={'div'}
              sx={
                {
                  display: 'flex'
                }
              }
            >
              <Box
                component={'div'}
                id={'menu-icon'}
                sx={
                  {
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center'
                  }
                }
                onClick={() => setMobileMenu(true)}
              >
                <MenuIcon 
                  sx={
                    { 
                      fontSize: 40, 
                      color: AllColors.menuIcon,
                      marginRight: '20px',
                      display: {
                        smallMobile: 'block',
                        web: 'none'
                      },
                      cursor: 'pointer'
                    }
                  } 
                />
              </Box>
              <Box
                component={'div'}
                sx={
                  {
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center'
                  }
                }
              >
                <Typography
                  variant='h2'
                  sx={
                    {
                      display: {
                        smallMobile: 'none',
                        mobile: 'block'
                      },
                      fontSize: {
                        smallMobile: '20px',
                        web: '24px'
                      }
                    }
                  }
                >
                  {
                    location !== '/'
                      ?
                      getText(location)
                      :
                      'Inicio'
                  }
                </Typography>
                {
                  location !== '/'
                    &&
                    <Typography
                      variant='h3'
                      sx={
                        {
                          fontWeight: {
                            smallMobile: 'bold',
                            mobile: '500'
                          },
                          fontSize: {
                            smallMobile: '20px',
                            mobile: '16px'
                          }
                        }
                      }
                    >
                      {user.brand ? user.brand : 'John Doe'}
                    </Typography>
                }
              </Box>
            </Box>
          </Box>
          {
            location === '/'
              ?
              <Box
                component={'div'}
                sx={
                    {
                        display: 'flex',
                        marginRight: {
                          smallMobile: '40px',
                          tablet: '60px',
                        }
                    }
                }
              >
                <ProgressIcon />
              </Box>
              :
              <Box
                component={'div'}
                sx={
                    {
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        width: {
                          smallMobile: '140px',
                          tablet: '200px'
                        },
                        marginRight: {
                              smallMobile: '30px',
                              tablet: '60px',
                        },
                    }
                }
              >
                <Box
                  component={'figure'}
                  onClick={() => navigate('/')}
                  sx={
                    {
                      cursor: 'pointer',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      height: {
                        xs: '25px',
                        md: '35px'
                      },
                      width: {
                        xs: '25px',
                        md: '35px'
                      }
                    }
                  }
                >
                  <img src={tipsIcon} alt={'Tips'} style={{ heigth: '100%', width: '100%' }} />
                </Box>
                <Box
                  component={'div'}
                  sx={
                      {
                        width: {
                          smallMobile: '90px',
                          tablet: '140px'
                        }
                      }
                  }
                >
                  <MainButton 
                    text={location === '/result' ? 'Publicar' : 'Continuar'}
                    onClick={onClick}
                    loading={false}
                    disabled={disabled}
                    color={'third'}
                  />
                </Box>
              </Box>
          }
      </Box>
      {
        mobileMenu &&
        <SidebarModal onClose={() => setMobileMenu(false)} />
      }
    </React.Fragment>
  )
}

export default Header