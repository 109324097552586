import { Button } from '@mui/material'
import React from 'react'
import { Colors } from 'theme/colors'
import modalButtonStyles from './modalbutton.module.css'

const ModalButton = ({handleClickAccept, handleClickCancel, text, accept=true}) => {
    const AllColors = new Colors()
    return (
        <Button
            variant='contained' 
            className={accept ? modalButtonStyles.accept : modalButtonStyles.cancel}
            onClick={accept ? handleClickAccept : handleClickCancel}
            sx={
                {
                    width: '150px',
                    height: '40px',
                    fontWeight: '600',
                    fontSize: '16px',
                    borderRadius: '8px',
                    cursor: 'pointer',
                    border: `1px solid ${AllColors.primary}`,
                }
            }
        >
            {text}
        </Button>
    )
}

export default ModalButton