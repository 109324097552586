import { useDispatch } from 'react-redux'
import { 
    setLinkBackgroundColor,
    setLinkBorder,
    setLinkButtonColor,
    setLinkFont,
    setLinkFullProduct,
    setLinkGreeting,
    setLinkLanguage,
    setLinkMessage,
    setLinkOtherCurrency,
    setLinkAdd,
    setGatewayBorder,
    setGatewayButtonColor,
    setGatewayFont,
    setGatewayFullProduct,
    setGatewayLanguage,
    setGatewayOtherCurrency,
    setGatewayAdd,
    resetState
} from '../redux/slices/productSlice'

/**
 * Hook that handles all state changes for a product, and functions to verify information and 
 * validate inputs
 */
const useProduct = () => {
    const dispatch = useDispatch();

    /**
     * All functions to handle state changes
     */
    const handleLinkBackgroundColor = (value) => {
        dispatch(setLinkBackgroundColor(value))
    }
    const handleLinkButtonColor = (value) => {
        dispatch(setLinkButtonColor(value))
    }
    const handleLinkLanguage = (value) => {
        dispatch(setLinkLanguage(value))
    }
    const handleLinkBorder = (value) => {
        dispatch(setLinkBorder(value))
    }
    const handleLinkFont = (value) => {
        dispatch(setLinkFont(value))
    }
    const handleLinkGreeting = (value) => {
        dispatch(setLinkGreeting(value))
    }
    const handleLinkMessage = (value) => {
        dispatch(setLinkMessage(value))
    }
    const handleLinkOtherCurrency = (value) => {
        dispatch(setLinkOtherCurrency(value))
    }
    const handleLinkFullProduct = (value) => {
        dispatch(setLinkFullProduct(value))
    }
    const handleLinkAdd = (value) => {
        dispatch(setLinkAdd(value))
    }
    const handleGatewayButtonColor = (value) => {
        dispatch(setGatewayButtonColor(value))
    }
    const handleGatewayLanguage = (value) => {
        dispatch(setGatewayLanguage(value))
    }
    const handleGatewayBorder = (value) => {
        dispatch(setGatewayBorder(value))
    }
    const handleGatewayFont = (value) => {
        dispatch(setGatewayFont(value))
    }
    const handleGatewayOtherCurrency = (value) => {
        dispatch(setGatewayOtherCurrency(value))
    }
    const handleGatewayFullProduct = (value) => {
        dispatch(setGatewayFullProduct(value))
    }
    const handleGatewayAdd = (value) => {
        dispatch(setGatewayAdd(value))
    }
    const handleResetState = () => {
        dispatch(resetState())
    }

    /**
     * Verifys if the product state is full
     * @param {Object} product Object with a product structure
     * @return {boolean}
     */
    const verifyInfo = (product) => {
        if (
            product.link.add  !== false ||
            product.gateway.add  !== false
            ) {
                return true;
        }
        return false;
    }

    /**
     * Verifys if the product-link state is full
     * @param {Object} product Object with a product-link structure
     * @return {boolean}
     */
    const verifyLinkInfo = (link) => {
        if (
            link.backgroundColor  !== '' &&
            link.buttonColor  !== '' &&
            link.font !== '' &&
            link.border !== '' &&
            link.language !== '' &&
            link.greeting !== '' &&
            link.message !== '' &&
            link.otherCurrency !== ''
            ) {
                return true;
        }
        return false;
    }

    /**
     * Verifys if the product-link state is full
     * @param {Object} product Object with a product-link structure
     * @return {boolean}
     */
    const verifyGatewayInfo = (gateway) => {
        if (
            gateway.buttonColor  !== '' &&
            gateway.font !== '' &&
            gateway.border !== '' &&
            gateway.language !== '' &&
            gateway.otherCurrency !== ''
            ) {
                return true;
        }
        return false;
    }

    /**
     * Validate if all inputs for a link object are not empty
     * @param {Object} link Object with a link structure
     * @return {Object}
     */
    const validateLinkInputs = (link) => {
        const linkObject = {}
        linkObject.backgroundColor = link.backgroundColor ? "" : "Requerido"
        linkObject.buttonColor = link.buttonColor ? "" : "Requerido"
        linkObject.font = link.font ? "" : "Requerido"
        linkObject.border = link.border ? "" : "Requerido"
        linkObject.language = link.language ? "" : "Requerido"
        linkObject.message = link.message ? "" : "Requerido"
        linkObject.greeting = link.greeting ? "" : "Requerido"

        return linkObject;
    }

    /**
     * Validate if all inputs for a link object are not empty
     * @param {Object} link Object with a link structure
     * @return {Object}
     */
        const validateGatewayInputs = (gateway) => {
            const gatewayObject = {}
            gatewayObject.buttonColor = gateway.buttonColor ? "" : "Requerido"
            gatewayObject.font = gateway.font ? "" : "Requerido"
            gatewayObject.border = gateway.border ? "" : "Requerido"
            gatewayObject.language = gateway.language ? "" : "Requerido"
    
            return gatewayObject;
        }

    return {
        handleLinkBackgroundColor,
        handleLinkButtonColor,
        handleLinkFont,
        handleLinkBorder,
        handleLinkLanguage,
        handleLinkGreeting,
        handleLinkMessage,
        handleLinkOtherCurrency,
        handleLinkAdd,
        handleLinkFullProduct,
        handleGatewayButtonColor,
        handleGatewayFont,
        handleGatewayBorder,
        handleGatewayLanguage,
        handleGatewayOtherCurrency,
        handleGatewayFullProduct,
        handleGatewayAdd,
        handleResetState,
        verifyLinkInfo,
        verifyGatewayInfo,
        validateGatewayInputs,
        validateLinkInputs,
        verifyInfo
    }
}
export {useProduct};