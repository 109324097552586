import { createSlice } from '@reduxjs/toolkit'

/**
 * UI object structure
 */
const initialState = {
    loading: false,
    error: {
        message: '',
        status: '',
        type: false
    },
    edit: false,
    editReference: false,
    delete: {},
    errorObject: {}
}

/**
 * UI state slice
 */
export const uiSlice = createSlice({
    name: 'ui',
    initialState,
    reducers: {
        setLoading: (state, action) => {
            state.loading = action.payload
        },
        setErrorMessage: (state, action) => {
            state.error.message = action.payload
        },
        setErrorStatus: (state, action) => {
            state.error.status = action.payload
        },
        setErrorType: (state, action) => {
            state.error.type = action.payload
        },
        resetErrorState: (state) => {
            state.error = initialState.error
        },
        setEdit: (state, action) => {
            state.edit = action.payload
        },
        setDelete: (state, action) => {
            state.delete = {...action.payload}
        },
        setErrorObject: (state, action) => {
            state.errorObject = {...action.payload}
        },
        setEditReference: (state, action) => {
            state.editReference = action.payload
        },
        reset: () => initialState
    }
});

export const { 
    setLoading, 
    setErrorMessage, 
    setErrorStatus, 
    setErrorType, 
    resetErrorState, 
    setEdit,
    setDelete,
    setErrorObject,
    setEditReference,
    reset
} = uiSlice.actions

export default uiSlice.reducer