import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { persistReducer } from 'redux-persist';
import storageSession from 'redux-persist/lib/storage/session'
import storage from 'redux-persist/lib/storage';
import uiReducer from './slices/uiSlice';
import brandReducer from './slices/brandSlice';
import branchReducer from './slices/branchSlice';
import productReducer from './slices/productSlice';
import sessionReducer from './slices/sessionSlice';
import serviceReducer from './slices/serviceSlice';
import referenceReducer from './slices/referenceSlice';
import autoMergeLevel1 from 'redux-persist/es/stateReconciler/autoMergeLevel1';

/**
 * Configurations for persisting the state in Local Storage
 */
const persistLocalConfig = {
    key: 'localStorage',
    storage,
    stateReconciler: autoMergeLevel1,
}

/**
 * Configurations for persisting the state in Session Storage
 */
const persistSessionConfig = {
    key: 'sessionStorage',
    storage: storageSession,
    stateReconciler: autoMergeLevel1,
}

/**
 * Combine all reducers
 */
const reducers = combineReducers({
    ui: uiReducer,
    brand: brandReducer,
    branch: branchReducer,
    service: serviceReducer,
    reference: referenceReducer,
    product: productReducer
})

/**
 * Creates a reducer that persists in Local Storage
 */
const persistedLocalReducer = persistReducer(persistLocalConfig, reducers)

/**
 * Creates a reducer that persists in Session Storage
 */
const persistedSessionReducer = persistReducer(persistSessionConfig, sessionReducer)

/**
 * Configures store
 */
export default configureStore({
    reducer: combineReducers({local: persistedLocalReducer, session: persistedSessionReducer}),
    middleware: getDefaultMiddleware => getDefaultMiddleware({serializableCheck: false}),
})