import { useDispatch } from 'react-redux'
import { 
    setLoading, 
    setErrorMessage, 
    setErrorStatus, 
    setErrorType, 
    resetErrorState, 
    setEdit,
    setDelete,
    setErrorObject,
    setEditReference,
    reset
} from '../redux/slices/uiSlice'


/**
 * Hook that handles all state changes for the UI, and functions to verify information and 
 * validate inputs
 */
const useUi = () => {
    const dispatch = useDispatch();
    
    /**
     * All functions to handle state changes
     */
    const handleLoading = (value) => {
        dispatch(setLoading(value))
    }
    const handleErrorMessage = (value) => {
        dispatch(setErrorMessage(value))
    }
    const handleErrorStatus = (value) => {
        dispatch(setErrorStatus(value))
    }
    const handleErrorType = (value) => {
        dispatch(setErrorType(value))
    }
    const handleResetError = () => {
        dispatch(resetErrorState())
    }
    const handleEditChange = (value) => {
        dispatch(setEdit(value))
    }
    const handleDelete = (value) => {
        dispatch(setDelete(value))
    }
    const handleErrorObjectChange = (value) => {
        dispatch(setErrorObject(value))
    }
    const handleEditReferenceChange = (value) => {
        dispatch(setEditReference(value))
    }
    const handleResetState = () => {
        dispatch(reset())
    }

    return {
        handleLoading,
        handleErrorMessage,
        handleErrorStatus,
        handleErrorType,
        handleResetError,
        handleEditChange,
        handleDelete,
        handleErrorObjectChange,
        handleEditReferenceChange,
        handleResetState
    }
}
export {useUi};