import { Button, Typography } from '@mui/material'
import { Colors } from 'theme/colors'
import React from 'react'
import LoadingIcon from 'components/Loading'

const MainButton = ({text, onClick, loading=false, disabled=false, color='primary'}) => {
  const AllColors = new Colors()
  return (
    <Button
      variant='contained'
      onClick={onClick}
      color={color}
      disabled={loading || disabled ? true : false}
      sx={
        {
          height: '40px',
          width: '100%',
          '&:hover': {
            backgroundColor: AllColors.lightBlue
          }
        }
      }
    >
      {
        loading
        ?
        <LoadingIcon
          size={2} 
          border={0.2} 
          firstColor={AllColors.white}
          secondColor={AllColors.white}
        />
        :
        <Typography
          variant='h4'
          sx={
            {
              color: AllColors.white
            }
          }
        >
          {text}
        </Typography>
      }
    </Button>
  )
}

export default MainButton