import { Box, Typography } from '@mui/material'
import React from 'react'
import { Colors } from 'theme/colors'

const CredentialBox = ({img, text, onClick}) => {
    const AllColors = new Colors()
    return (
        <Box
            component={'div'}
            sx={
                {
                    height: '60px',
                    width: '100%',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'flex-start',
                    marginBottom: '10px',
                    padding: '10px 0',
                    cursor: 'pointer',
                    borderRadius: '8px',
                    '&:hover': {
                        backgroundColor: AllColors.darkBlueA
                    }
                }
            }
            onClick={onClick}
        >
            <Box
                component={'figure'}
                sx={
                    {
                        height: '35px',
                        width: '35px',
                        margin: '0 15px'
                    }
                }
            >
                <img style={{width: '100%'}} src={img} alt={'Menu'} />
            </Box>
            <Typography
                variant='h4'
                sx={
                    {
                        color: AllColors.white,
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        maxWidth: '125px',
                    }
                }
            >
                {text}
            </Typography>
        </Box>
    )
}

export default CredentialBox