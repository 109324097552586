import { useDispatch } from 'react-redux'
import { 
    setId, 
    setCountryName,
    setCountryCode, 
    setName, 
    setSocialReason,
    setRif,
    setPhoneNumber,
    setPhoneNumberCode,
    setAddress,
    setCurrency,
    setServices, 
    deleteService,
    setFullBranch,
    resetState,
    changeCurrencyInService, 
} from 'redux/slices/branchSlice'

/**
 * Hook that handles all state changes for a branch, and functions to verify information and 
 * validate inputs
 */
const useBranch = () => {
    const dispatch = useDispatch();

    /**
     * All functions to handle state changes
     */
    const handleIdChange = () => {
        dispatch(setId())
    }
    const handleCountryNameChange = (value) => {
        dispatch(setCountryName(value))
    }
    const handleCountryCodeChange = (value) => {
        dispatch(setCountryCode(value))
    }
    const handleNameChange = (value) => {
        dispatch(setName(value))
    }
    const handleSocialReasonChange = (value) => {
        dispatch(setSocialReason(value))
    }
    const handleRifChange = (value) => {
        dispatch(setRif(value))
    }
    const handlePhoneNumberChange = (value) => {
        dispatch(setPhoneNumber(value))
    }
    const handlePhoneNumberCodeChange = (value) => {
        dispatch(setPhoneNumberCode(value))
    }
    const handleAddressChange = (value) => {
        dispatch(setAddress(value))
    }
    const handleCurrencyChange = (value) => {
        dispatch(setCurrency(value))
    }
    const handleServiceChange = (value) => {
        dispatch(setServices(value))
    }
    const handleDeleteService = (value) => {
        dispatch(deleteService(value))
    }
    const handleFullBranch = (branch) => {
        dispatch(setFullBranch(branch))
    }
    const handleResetState = () => {
        dispatch(resetState())
    }

    /**
     * Verifys if the branch state is full, before adding it to a brand
     * @param {Object} branch Object with a branch structure
     * @return {boolean}
     */
    const verifyInfo = (branch) => {
        if (
            branch.country.name !== '' &&
            branch.name !== '' && 
            branch.socialReason !== '' && 
            branch.phone.number !== '' && 
            branch.phone.code !== '' && 
            branch.address !== '' &&
            branch.currency.length > 0
            ) {
                return true;
        }
        return false;
    }
    
    /**
     * Validate if all inputs for a branch are not empty
     * @param {Object} branch Object with a branch structure
     * @return {Object}
     */
    const validateInputs = (branch) => {
        const branchObject = {}
        branchObject['country'] = {
            name: branch.country.name ? "" : "Requerido",
            code: branch.country.code ? "" : "Requerido"
        }
        branchObject.name = branch.name ? "" : "Requerido"
        branchObject.socialReason = branch.socialReason ? "" : "Requerido"
        branchObject.address = branch.address ? "" : "Requerido"
        branchObject.currency = branch.currency.length > 0 ? "" : "Requerido"
        branchObject['phone'] = {
            number: branch.phone.number.length > 0  ? "" : "Requerido",
            code: branch.phone.code.length > 0  ? "" : "Requerido"
        }

        return branchObject;
    }

    /**
     * Validates that all services have the same currency as the branch
     * @param {Object} branch Object with a branch structure
     * @param {Object} service Object with a service structure
     */
    const validateServices = (branch, services) => {
        services.forEach((service) => {
            service.currency.forEach((currency) => {
                if (!branch.currency.includes(currency)) {
                    dispatch(changeCurrencyInService({service: service, currency: currency}))
                }
            })
        })
    }

    return {
        handleIdChange,
        handleCountryNameChange,
        handleCountryCodeChange,
        handleNameChange,
        handleSocialReasonChange,
        handleRifChange,
        handlePhoneNumberChange,
        handlePhoneNumberCodeChange,
        handleAddressChange,
        handleCurrencyChange,
        handleServiceChange,
        handleDeleteService,
        handleFullBranch,
        handleResetState,
        verifyInfo,
        validateInputs,
        validateServices
    }
}
export {useBranch};