import { createSlice } from '@reduxjs/toolkit'

/**
 * User object structure
 */
const initialState = {
    username: 'ruben@lukapay.io',
    accessToken: '5743857kgh54',
    brand: 'Luka'
}

/**
 * User state slice
 */
export const sessionSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        setUsername: (state, action) => {
            state.username = action.payload
        },
        setAccessToken: (state, action) => {
            state.accessToken = action.payload
        },
        setBrandName: (state, action) => {
            state.accessToken = action.payload
        },
        resetState: () => initialState,
    }
});

export const { 
    setUsername, 
    setAccessToken,
    setBrandName,
    resetState
} = sessionSlice.actions

export default sessionSlice.reducer