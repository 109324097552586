import { createSlice } from '@reduxjs/toolkit'

/**
 * Service object structure
 */
const initialState = {
    id: '',
    name: '',
    description: '',
    currency: [],
    payment: [],
    products: {},
    references: []
}

/**
 * Service state slice
 */
export const serviceSlice = createSlice({
    name: 'service',
    initialState,
    reducers: {
        setId: (state) => {
            state.id = crypto.randomUUID()
        },
        setName: (state, action) => {
            state.name = action.payload
        },
        setDescription: (state, action) => {
            state.description = action.payload
        },
        setReference: (state, action) => {
            state.references = [...state.references, action.payload]
        },
        setCurrency: (state, action) => {
            state.currency = [...action.payload].sort()
        },
        setPayment: (state, action) => {
            state.payment = [...action.payload].sort()
        },
        deleteReference: (state, action) => {
            const index = state.references.findIndex((reference) => reference.id === action.payload.id)
            if (index >= 0) {
                state.references.splice(index, 1)
            } else {
                state.references = [];
            }
        },
        setFullService: (state, action) => state = action.payload,
        resetState: () => initialState,
    }
});

export const { 
    setId, 
    setName, 
    setDescription, 
    setReference,
    deleteReference,
    setFullService, 
    setCurrency,
    setPayment,
    resetState, 
} = serviceSlice.actions

export default serviceSlice.reducer