import { createSlice } from '@reduxjs/toolkit'

/**
 * Brand object structure
 */
const initialState = {
    id: '',
    name: '',
    country: {
        name: '',
        code: ''
    },
    category: '',
    subCategory: '',
    logo: {
        url: "",
    },
    isotypeLogo: {
        url: "",
    },
    branches: []
}
// const initialState = {
//     id: '81f5121d-a3e5-45df-931f-0d22dd820261',
//     name: 'dfghdfghdfgh',
//     country: {
//         name: 'Venezuela',
//         code: 'VE'
//     },
//     category: 'Health',
//     subCategory: 'Food Court',
//     logo: {
//         url: "https://cdn.filestackcontent.com/Gbbjv1EmTaK3Lek7w0qK",
//     },
//     isotypeLogo: {
//         url: "https://cdn.filestackcontent.com/ZnrBVn8RQL6il0mafogf",
//     },
//     branches: [
//         {
//             id: 'dfghdfg43563sdsd54',
//             country: {
//                 name: 'Venezuela',
//                 code: 'VE'
//             },
//             name: 'Luka 1',
//             socialReason: 'Luka 1',
//             rif: '32132132',
//             phone: {
//                 number: '1325464',
//                 code: '58',
//             },
//             address: 'asdfasdfsadf',
//             currency: ['USD', 'VES'],
//             services: []
//         },
//         {
//             id: 'dfghdfg43563',
//             country: {
//                 name: 'Venezuela',
//                 code: 'VE'
//             },
//             name: 'Luka 2',
//             socialReason: 'Luka 2',
//             rif: '32132132',
//             phone: {
//                 number: '1325464',
//                 code: '58',
//             },
//             address: 'asdfasdfsadf',
//             currency: ['USD', 'VES'],
//             services: []
//         },
//         {
//             id: 'dfghdfgdddd43563',
//             country: {
//                 name: 'Venezuela',
//                 code: 'VE'
//             },
//             name: 'Luka 3',
//             socialReason: 'Luka 3',
//             rif: '32132132',
//             phone: {
//                 number: '1325464',
//                 code: '58',
//             },
//             address: 'asdfasdfsadf',
//             currency: ['USD', 'VES'],
//             services: []
//         },
//         {
//             id: 'dfghdffffddfg43563',
//             country: {
//                 name: 'Venezuela',
//                 code: 'VE'
//             },
//             name: 'Luka 4',
//             socialReason: 'Luka 4',
//             rif: '32132132',
//             phone: {
//                 number: '1325464',
//                 code: '58',
//             },
//             address: 'asdfasdfsadf',
//             currency: ['USD', 'VES'],
//             services: []
//         },
//         {
//             id: 'dfghdfg454543563',
//             country: {
//                 name: 'Venezuela',
//                 code: 'VE'
//             },
//             name: 'Luka 5',
//             socialReason: 'Luka 5',
//             rif: '32132132',
//             phone: {
//                 number: '1325464',
//                 code: '58',
//             },
//             address: 'asdfasdfsadf',
//             currency: ['USD', 'VES'],
//             services: []
//         },
//         {
//             id: 'dfghdfg4874dd3563',
//             country: {
//                 name: 'Venezuela',
//                 code: 'VE'
//             },
//             name: 'Luka 6',
//             socialReason: 'Luka 6',
//             rif: '32132132',
//             phone: {
//                 number: '1325464',
//                 code: '58',
//             },
//             address: 'asdfasdfsadf',
//             currency: ['USD', 'VES'],
//             services: []
//         }
//     ]
// }


/**
 * Brand state slice
 */
export const brandSlice = createSlice({
    name: 'brand',
    initialState,
    reducers: {
        setId: (state) => {
            state.id = crypto.randomUUID()
        },
        setName: (state, action) => {
            state.name = action.payload
        },
        setCountryName: (state, action) => {
            state.country.name = action.payload
        },
        setCountryCode: (state, action) => {
            state.country.code = action.payload
        },
        setCategory: (state, action) => {
            state.category = action.payload
        },
        setSubCategory: (state, action) => {
            state.subCategory = action.payload
        },
        setLogo: (state, action) => {
            state.logo = action.payload
        },
        setIsotypeLogo: (state, action) => {
            state.isotypeLogo = action.payload
        },
        setBranch: (state, action) => {
            const filtered = state.branches.filter((branch) => branch.id === action.payload.id)
            if (filtered.length === 0) {
                state.branches = [...state.branches, action.payload]
            }
        },
        deleteBranch: (state, action) => {
            const index = state.branches.findIndex((branch) => branch.id === action.payload.id)
            if (index >= 0) {
                if (state.branches.length > 1) {
                    state.branches.splice(index, 1)
                } else {
                    state.branches = []
                }
            }
        },
        setServiceInBranch: (state, action) => {
            state.branches.forEach((branch) => {
                if (branch.name === action.payload.name) {
                    branch.services = [...action.payload.services]
                }
            })
        },
        massiveAddService: (state, action) => {
            state.branches.forEach((branch) => {
                branch.services = [...branch.services, action.payload]
            })
        },
        massiveAddProductInService: (state, action) => {
            if (state.branches.length > 0) {
                state.branches.forEach((branch) => {
                    if (branch.services.length > 0) {
                        branch.services.forEach((service) => {
                            if (action.payload.gateway.add) {
                                service.products = {...service.products, gateway: action.payload.gateway}
                            }
                            if (action.payload.link.add) {
                                service.products = {...service.products, link: action.payload.link}
                            }
                        })
                    }
                })
            }
        },
        deleteServiceInBranch: (state, action) => {
            const branchIndex = state.branches.findIndex((branch) => branch.name === action.payload.branch.name)
            if (branchIndex >= 0) {
                const serviceIndex = state.branches[branchIndex].services.findIndex((service) => service.name === action.payload.service.name)
                if (serviceIndex >= 0) {
                    if (state.branches[branchIndex].services.length > 1) {
                        state.branches[branchIndex].services.splice(serviceIndex, 1)
                    } else {
                        state.branches[branchIndex].services = []
                    }
                }
            }
        },
        resetState: () => initialState,
    }
});

export const { 
    setId,
    setName, 
    setCountryName,
    setCountryCode,
    setCategory, 
    setSubCategory, 
    setLogo, 
    setIsotypeLogo,
    setBranch,
    deleteBranch,
    setServiceInBranch,
    massiveAddService,
    deleteServiceInBranch,
    massiveAddProductInService,
    resetState,
} = brandSlice.actions

export default brandSlice.reducer