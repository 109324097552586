import { Box, List, ListItem, Typography } from '@mui/material'
import MainButton from 'components/Buttons/MainButton'
import InnerLayout from 'layout/InnerLayout'
import React, { useEffect, useState } from 'react'
import TipsBox from './TipsBox'
import tipsBag from 'assets/icons/tips-bag.svg'
import tipsBuilding from 'assets/icons/tips-building.svg'
import tipsTask from 'assets/icons/tips-task.svg'
import tipsStyles from './tips.module.css'
import { useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'

const Tips = () => {
  const navigate = useNavigate()
  const [tooltip, setTooltip] = useState(false)
  const user = useSelector(state => state.session)
  const brand = useSelector(state => state.local.brand)
  const product = useSelector(state => state.local.product)
  const handleClick = () => {
    try {
      if (brand.branches.length > 0) {
        if (brand.branches[0].services.length > 0) {
          if (Object.entries(product).length > 0) {
            if (product.gateway.add || product.link.add) {
              navigate('/result')
            } else {
              navigate('/product')
            }
          } else {
            navigate('/service')
          }
        } else {
          navigate('/branch')
        }
      } else {
        navigate('/brand')
      }
    } catch (e) {
      navigate('/brand')
    }
  }

  useEffect(() => {
    if (!brand.id) {
      setTooltip(true)
    }
  }, [])

  useEffect(() => {
    if (tooltip) {
      setTimeout(() => {
        setTooltip(false)
      }, 5000)
    }
  }, [tooltip])
  
  return (
    <InnerLayout onClick={() => navigate('/')}>
      <Box
        component={'div'}
        sx={
          {
            height: '100%',
            width: '100%',
            display: 'flex',
            overflow: 'hidden',
            overflowY: 'auto',
            '&::-webkit-scrollbar':{
                width: '0px'
            },
            flexDirection: {
              smallMobile: 'column',
              web: 'row'
            }
          }
        }
      >
        <Box
          component={'div'}
          sx={
            {
              order: {
                smallMobile: 2,
                web: 1
              },
              height: {
                smallMobile: 'auto',
                web: '100%'
              },
              width: {
                smallMobile: '100%',
                web: 'calc(100% - 390px)'
              },
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'space-between',
              padding: {
                smallMobile: '20px 30px 0px 30px',
                web: '50px 10px 0 40px'
              }
            }
          }
        >
          <Box
            component={'div'}
          >
            <Typography
              variant='h6'
              sx={
                {
                  marginBottom: {
                    smallMobile: '10px',
                    web: '20px'
                  }
                }
              }
            >
              Bienvenido(a) {user.brand}!
            </Typography>
            <Box
              component={'div'}
              sx={
                {
                  marginBottom: {
                    smallMobile: '25px',
                    web: '35px'
                  }
                }
              }
            >
              <Typography
                variant='h4'
                sx={
                  {
                    marginBottom: '20px'
                  }
                }
              >
                Lea las definiciones para facilitar el proceso de afilición
              </Typography>
              <Typography
                variant='paragraph'
              >
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.
              </Typography>
            </Box>
            <Box
              component={'div'}
            >
              <Typography
                variant='h4'
              >
                Subtitle
              </Typography>
              <List>
                <ListItem
                  sx={
                    {
                      padding: {
                        smallMobile: '5px 16px',
                        web: '8px 16px'
                      }
                    }
                  }
                >
                  <Typography
                    variant='paragraph'
                  >
                    <span className={tipsStyles.span}>Definición:</span> Integre nuestra solución y comience a procesar pagos en múltiples divisas con múltiples métodos de pago.
                  </Typography>
                </ListItem>
                <ListItem>
                  <Typography
                    variant='paragraph'
                  >
                    <span className={tipsStyles.span}>Definición:</span> Haz que la pasarela diga y mire lo que quieras.
                  </Typography>
                </ListItem>
                <ListItem>
                  <Typography
                    variant='paragraph'
                  >
                    <span className={tipsStyles.span}>Definición:</span> Integra la solución en un abrir y cerrar de ojos a través de nuestras APIs.
                  </Typography>
                </ListItem>
                <ListItem>
                  <Typography
                    variant='paragraph'
                  >
                    <span className={tipsStyles.span}>Definición:</span> Integra la solución en un abrir y cerrar de ojos a través de nuestras APIs.
                  </Typography>
                </ListItem>
              </List>
            </Box>
          </Box>
          <Box
            sx={
              {
                width: '80%',
                maxWidth: '340px',
                marginBottom: '30px',
                marginTop: {
                  smallMobile: '15px',
                  web: '15px'
                },
                position: 'relative'
              }
            }
          >
            <Box
              className={tipsStyles['tooltip-container']}
              sx={
                {
                  position: 'absolute',
                  top: '-45px',
                  left: '80px',
                  zIndex: '10',
                  alignItems: 'center',
                  justifyContent: 'center',
                  flexDirection: 'column',
                  display: tooltip ? 'flex' : 'none'
                }
              }
            >
              <Box
                className={tipsStyles['tooltip-text-box']}
                sx={
                  {
                    boxShadow: '0px 8px 16px -2px rgba(27, 33, 44, 0.12)',
                    padding: '10px 15px',
                    backgroundColor: 'white',
                    borderRadius: '8px'
                  }
                }
              >
                <Typography
                  className={tipsStyles['tooltip-text']}
                  sx={
                    {
                      fontSize: '12px !important'
                    }
                  }
                >
                  Empieza tu proceso de afiliación
                </Typography>
              </Box>
              <Box
                className={tipsStyles['tooltip-triangle']}
              ></Box>
            </Box>
            <MainButton text={brand.id ? 'Continuar' : 'Empezar'} loading={false} onClick={handleClick} />
          </Box>
        </Box>
        <Box
          draggable={true}
          component={'div'}
          sx={
                {
                  order: {
                    smallMobile: 1,
                    web: 2
                  },
                  overflowY: {
                    smallMobile: 'hidden',
                    web: 'auto'
                  },
                  overflowX: {
                    smallMobile: 'auto',
                    web: 'hidden'
                  },
                  height: {
                    smallMobile: '250px',
                    web: '100%'
                    },
                  minHeight: {
                    smallMobile: '250px',
                    web: '620px'
                  },
                  width: {
                    smallMobile: '100%',
                    web: '390px'
                  },
                  minWidth: {
                    smallMobile: '100%',
                    web: '300px'
                  },
                }
              }
          >
          <Box
            draggable={true}
            component={'div'}
            sx={
              {
                display: 'flex',
                alignItems: 'center',
                margin: '0 auto',
                justifyContent: 'space-evenly',
                height: {
                  smallMobile: '250px',
                  web: '100%'
                  },
                width: {
                  smallMobile: '950px',
                  web: '390px'
                },
                flexDirection: {
                  smallMobile: 'row',
                  web: 'column'
                },
              }
            }
          >
            <TipsBox 
              img={tipsTask}
              text={'Puedes salir en medio del proceso y retomar proceso del mismo cuando quieras.'}
            />
            <TipsBox 
              img={tipsBuilding}
              text={'Conoce la estructura corporativa de la empresa para un registro mas acertivo.'}
            />
            <TipsBox 
              img={tipsBag}
              text={'Verifica nuestros productos y servicios disponibles para elegir el que mas se adapte a las necesidades de la empresa.'}
            />
          </Box>
        </Box>
      </Box>
    </InnerLayout>
  )
}

export default Tips