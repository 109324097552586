import { createSlice } from '@reduxjs/toolkit'

/**
 * Branch object structure
 */
const initialState = {
    id: '',
    country: {
        name: 'Venezuela',
        code: 'VE'
    },
    name: '',
    socialReason: '',
    rif: '',
    phone: {
        number: '',
        code: '58',
    },
    address: '',
    currency: [],
    services: []
}

/**
 * Branch state slice
 */
export const branchSlice = createSlice({
    name: 'branch',
    initialState,
    reducers: {
        setId: (state) => {
            state.id = crypto.randomUUID()
        },
        setCountryName: (state, action) => {
            state.country.name = action.payload
        },
        setCountryCode: (state, action) => {
            state.country.code = action.payload
        },
        setName: (state, action) => {
            state.name = action.payload
        },
        setSocialReason: (state, action) => {
            state.socialReason = action.payload
        },
        setRif: (state, action) => {
            state.rif = action.payload
        },
        setPhoneNumber: (state, action) => {
            state.phone.number = action.payload
        },
        setPhoneNumberCode: (state, action) => {
            state.phone.code = action.payload
        },
        setAddress: (state, action) => {
            state.address = action.payload
        },
        setCurrency: (state, action) => {
            state.currency = [...action.payload].sort()
        },
        setServices: (state, action) => {
            state.services = [...state.services, action.payload]
        },
        changeCurrencyInService: (state, action) => {
            const index = state.services.findIndex((service) => service.id === action.payload.service.id)
            if (index >= 0) {
                const currencyIndex = state.services[index].currency.findIndex((currency) => currency === action.payload.currency)
                if (currencyIndex >= 0) {
                    state.services[index].currency.splice(currencyIndex, 1)
                    state.services[index].payment = []
                } else {
                    state.services[index].currency = [...state.services[index].currency, action.payload.currency]
                }
            }
        },
        deleteService: (state, action) => {
            const index = state.services.findIndex((service) => service.id === action.payload.id)
            if (index >= 0) {
                if (state.services.length > 1) {
                    state.services.splice(index, 1)
                } else {
                    state.services = []
                }
            }
        },
        setFullBranch: (state, action) => state = action.payload,
        resetState: () => initialState,
    }
});

export const { 
    setId, 
    setCountryName,
    setCountryCode, 
    setName, 
    setSocialReason,
    setRif,
    setPhoneNumber,
    setPhoneNumberCode,
    setAddress,
    setCurrency,
    setServices, 
    changeCurrencyInService,
    deleteService,
    setFullBranch,
    resetState, 
} = branchSlice.actions

export default branchSlice.reducer