/**
 * Class that has all colors usable in the app
 * @class
 */
class Colors {
    constructor() {
        this.black= '#000000'
        this.white = '#FFFFFF'
        this.primary = '#0878FF'
        this.primaryO = '#0878ff14'
        this.lightBlue = '#3ca2f6'
        this.buttonBlue = '#0516B1'
        this.darkBlue = '#000853'
        this.darkBlueA= '#000A65'
        this.aquaBlue = '#5AE1E2'
        this.principalGray = '#ACB9C9'
        this.principalGrayA = '#acb9c986'
        this.grayText = '#616F81'
        this.grayTextA = '#616f81b2'
        this.darkGrayText = '#2F363E'
        this.menuIcon = '#5D6C7C'
        this.lightGrayText = '#ACB9C9'
        this.nav = '#CED9DF'
        this.background = '#F6F7FB'
        this.lightGray= '#E6ECF6'
        this.principalGreen = '#91E02B'
        this.error = '#d32f2f'
        this.errorOpacity = '#FEF1F2'
    }
}

export { Colors }