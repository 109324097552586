import { Box } from '@mui/material'
import Sidebar from 'components/Sidebar'
import React from 'react'

const MainLayout = ({children}) => {
  return (
    <Box
      component={'main'}
      sx={
        {
          height: '100%',
          width: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between'
        }
      }
    >
        <Sidebar />
        <Box
          component={'section'}
          sx={
            {
              height: '100%',
              width: {
                smallMobile: '100%',
                web: 'calc(100% - 250px)',
              }
            }
          }
        >
            {children}
        </Box>
    </Box>
  )
}

export default MainLayout