import { Box } from '@mui/material';
import React from 'react'
import ReactDOM from 'react-dom';
import { Colors } from 'theme/colors';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import Credentials from 'components/Sidebar/Credentials'
import NavComponent from 'components/Sidebar/NavComponent'

/**
 * Modal that displays the menu in mobile
 */
const SidebarModal = ({onClose}) => {
    const AllColors = new Colors()
    return ReactDOM.createPortal(
        <Box
            component={'div'}
            sx={
                {
                    width: '100%',
                    height: '100%',
                    backgroundColor: AllColors.principalGrayA,
                    top: 0,
                    position: 'fixed',
                }
            }
        >
            <Box 
                component={'aside'}
                sx={
                    {
                    height: '100%',
                    width: '250px',
                    flexDirection: 'column',
                    alignItems: 'center',
                    backgroundColor: AllColors.darkBlue,
                    bottom: '0px',
                    display: 'flex',
                    justifyContent: 'space-between',
                    zIndex: 100,
                    overflowY: 'auto',
                    }
                }
                >
                    <Box
                        component={'div'}
                        sx={
                            {
                                margin: '30px 20px 0 0',
                                alignSelf: 'flex-end',
                            }
                        }
                        onClick={onClose}
                    >
                        <ArrowBackIosIcon sx={{fontSize: 30, color: '#F6F7FB', cursor: 'pointer'}}/>
                    </Box>
                <NavComponent type={'mobile'} />
                <Credentials />
            </Box>
        </Box>,
        document.getElementById('mobile-menu')
    )
}

export default SidebarModal