import { createSlice } from '@reduxjs/toolkit'

/**
 * Brand object structure
 */
const initialState = {
    id: '',
    name: '',
    type: '',
    long: '',
    example: ''
}

/**
 * Reference state slice
 */
export const referenceSlice = createSlice({
    name: 'reference',
    initialState,
    reducers: {
        setId: (state) => {
            state.id = crypto.randomUUID()
        },
        setName: (state, action) => {
            state.name = action.payload
        },
        setType: (state, action) => {
            state.type = action.payload
        },
        setLong: (state, action) => {
            state.long = action.payload
        },
        setExample: (state, action) => {
            state.example = action.payload
        },
        resetState: () => initialState,
        setFullReference: (state, action) => state = action.payload,
    }
});

export const { 
    setId, 
    setName, 
    setType, 
    setLong, 
    setExample, 
    setFullReference,
    resetState ,
} = referenceSlice.actions

export default referenceSlice.reducer