import { Colors } from './colors'
import { createTheme, ThemeProvider } from '@mui/material'

const Theme = ({ children }) => {
    const AllColors = new Colors()
    const css = {
      color: AllColors.grayText,
      background: AllColors.white,
      fontSize: 14,
      labelFontSize: 14,
      textHelperSize: '10px',
    }

    const themenew = createTheme({
      components: {
        MuiSlider: {
          styleOverrides: {
            track: {
              backgroundColor: AllColors.principalBlue,
            },
            thumb: {
              backgroundColor: AllColors.principalBlue,
              '&:hover': {
                boxShadow: 'none',
              },
              '&:after': {
                width: '30px',
                height: '30px'
              }
            },
            valueLabel: {
              '&:before': {
                backgroundColor: AllColors.principalBlue,
                color: AllColors.greyText,
              },
              '&:after': {
                backgroundColor: AllColors.principalBlue,
                color: AllColors.greyText,
              },
            },
            valueLabelOpen: {
              '&:before': {
                backgroundColor: AllColors.principalBlue,
                color: AllColors.greyText,
              },
              '&:after': {
                backgroundColor: AllColors.principalBlue,
                color: AllColors.greyText,
              },
            }
          }
        },
        MuiPaper: {
          styleOverrides: {
            root: {
              width: '300px'
            }
          }
        },
        MuiInput: {
          styleOverrides: {
            underline: {
              '&.Mui-focused': {
                borderColor: AllColors.lightGray,
                transition: 'none'
              },
              "&:before": {
                borderColor: AllColors.lightGray,
                transition: 'none'
              },
              "&:after": {
                borderColor: AllColors.lightGray,
                transition: 'none'
              },
              '&:hover:not(.Mui-disabled):before': {
                borderColor: AllColors.primary,
                transition: 'none'
              },
              '&:hover:not(.Mui-disabled, .Mui-error):before': {
                borderColor: AllColors.primary,
                transition: 'none'
              },
            }
          }
        },
        MuiInputLabel: {
          styleOverrides: {
            root: {
              '&.Mui-error': {
                color: css.color,
              },
              '&.Mui-focused': {
                color: css.color,
              }
            }
          }
        },
        MuiButton: {
          styleOverrides: {
            root: {
              borderRadius: '8px',
              textTransform: 'none',
              boxShadow: 'none',
              '&:hover': {
                boxShadow: 'none'
              }
            }
          }
        },
        MuiTextField: {
          styleOverrides: {
            root: {
              width: '100%',
            },
          }
        },
        MuiSelect: {
          styleOverrides: {
            root: {
            },
            select: {
              color: css.color,
              fontSize: css.fontSize,
              backgroundColor: 'transparent',
              '&:focus': { backgroundColor: 'transparent' },
            }
          }
        },
        MuiInputBase: {
          styleOverrides: {
            root: {
              color: css.color,
              fontSize: css.fontSize,
              '&focus-within::after': {
                transform: 'none'
              }
            }
          }
        },
        MuiFormLabel: {
          styleOverrides: {
            root:{
              fontSize: css.labelFontSize,
              color: css.color,
              backgroundColor: 'transparent',
              zIndex: 4,
              fontFamily: `'Open Sans', sans-serif`,
              '&$error': {
                color: css.color,
              }
            }
          }
        },
        MuiFormHelperText: {
          styleOverrides: {
            root: {
              fontSize: css.textHelperSize
            }
          }
        },
        MuiTypography: {
          styleOverrides: {
            root: {
              letterSpacing: '-0.02em',
              lineHeight: '150%'
            },
            h1: {
              fontSize: '34px',
              fontWeight: '600',
              color: AllColors.darkGrayText
            },
            h2: {
              fontSize: '24px',
              fontWeight: 'bold',
              color: AllColors.darkGrayText
            },
            h3: {
              fontSize: '16px',
              fontWeight: '500',
              color: AllColors.darkGrayText
            },
            h4: {
              fontSize: '14px',
              fontWeight: '600',
              color: AllColors.darkGrayText
            },
            h5: {
              fontSize: '16px',
              fontWeight: 'bold',
              color: AllColors.darkGrayText
            },
            h6: {
              fontSize: '20px',
              fontWeight: '600',
              color: AllColors.darkGrayText
            },
            paragraph: {
              fontSize: '14px',
              fontWeight: 'normal',
              color: AllColors.darkGrayText
            }
          }
        }
      },
      palette: {
        primary: {
          light: AllColors.primary,
          main: AllColors.primary,
          dark: AllColors.primary,
          contrastText: '#fff'
        },
        secondary: {
          light: AllColors.lightBlue,
          main: AllColors.lightBlue,
          dark: AllColors.lightBlue,
          contrastText: '#fff'
        },
        third: {
          light: AllColors.buttonBlue,
          main: AllColors.buttonBlue,
          dark: AllColors.buttonBlue,
          contrastText: '#fff'
        },
        green_luka: {
          light: AllColors.principalGreen,
          main: AllColors.principalGreen,
          dark: AllColors.principalGreen,
          contrastText: '#fff'
        },
        disabled: {
          light: AllColors.principalGray,
          main: AllColors.principalGray,
          dark: AllColors.principalGray,
          contrastText: '#fff'
        }
      },
      typography: {
        fontFamily: 'Open Sans, sans-serif',
      },
      breakpoints: {
        values: {
            xs: false,
            sm: false,
            md: false,
            lg: false,
            xl: false,
            smallMobile: 0,
            mobile: 500,
            largeMobile: 750,
            tablet: 990,
            web: 1300,
        }
      }
    })
    return <ThemeProvider theme={themenew}>{children}</ThemeProvider>
  }
  
export default Theme
  