import { Box } from '@mui/material'
import Header from 'components/Header'
import React from 'react'
import { Colors } from 'theme/colors'
import styles from 'styles/global.module.css'

const InnerLayout = ({children, onClick, disabled=false}) => {
  const AllColors = new Colors()
  return (
    <React.Fragment>
      <Header onClick={onClick} disabled={disabled} />
      <Box
        className={styles['flex-column-vert-hor-center']}
        component={'div'}
        sx={
          {
            backgroundColor: AllColors.background,
            height: 'calc(100% - 125px)'
          }
        }
      >
        <Box
          component={'div'}
          sx={
            {
              height: '90%',
              maxHeight: '750px',
              borderRadius: '8px',
              backgroundColor: AllColors.white,
              width: {
                smallMobile: '90%',
                tablet: '990px'
              },
            }
          }
        >
          {children}
        </Box>
      </Box>
    </React.Fragment>
  )
}

export default InnerLayout