import { Box, Typography } from '@mui/material'
import React from 'react'
import { Colors } from 'theme/colors'
import navboxStyles from './navbox.module.css'
import './index.css'

const NavBox = ({id, text, img, onClick}) => {
    const AllColors = new Colors()
    return (
        <Box 
            id={id}
            className={navboxStyles['menu-nav-disabled']}
            component={'div'}
            sx={
                {
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'flex-start',
                    color: AllColors.nav,
                    cursor: 'pointer',
                    zIndex: 2,
                }
            }
            onClick={onClick}
        >
            <Box 
                className='nav-icon'
                component={'figure'}
                sx={
                    {
                        borderRadius: '50%',
                        backgroundColor: AllColors.background,
                        marginRight: '14px',
                        padding: '20px',
                        width: '35px',
                        height: '35px',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }
                }
            >
                <img className={navboxStyles['nav-img']} loading="lazy" src={img} alt={text} />
            </Box>
            <Typography 
                variant='h4'
                sx={
                    {
                        color: AllColors.white,
                    }
                }
            >
                {text}
            </Typography>
        </Box>
    )
}

export default NavBox