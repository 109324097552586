import { PersistGate } from 'redux-persist/integration/react';
import { persistStore } from 'redux-persist';
import { Provider } from 'react-redux';
import ReactDOM from 'react-dom/client';
import Theme from './theme';
import store from 'redux/store';
import React from 'react';
import './index.css'
import AppRoutes from 'router';

const root = ReactDOM.createRoot(document.getElementById('root'));
const persistor = persistStore(store)
root.render(
  <React.StrictMode>
    <Provider store={store} >
      <PersistGate loading={null} persistor={persistor} >
        <Theme>
          <AppRoutes />
        </Theme>
      </PersistGate>
    </Provider>
  </React.StrictMode>
);
