import { createSlice } from '@reduxjs/toolkit'

/**
 * Product object structure
 */
const initialState = {
    link: {
        backgroundColor: '#0878FF',
        buttonColor: '#0636CA',
        language: '2r5645754ar5',
        border: 0,
        font: '2r565w54ar5',
        greeting: 'Hola estimado cliente,',
        message: 'le hemos solicitado un pago',
        otherCurrency: false,
        add: false,
    },
    gateway: {
        buttonColor: '#0636CA',
        language: '2r5645754ar5',
        border: 0,
        font: '2r565w54ar5',
        otherCurrency: false,
        add: false,
    }
}

/**
 * Product state slice
 */
export const serviceSlice = createSlice({
    name: 'product',
    initialState,
    reducers: {
        setLinkBackgroundColor: (state, action) => {
            state.link.backgroundColor = action.payload
        },
        setLinkButtonColor: (state, action) => {
            state.link.buttonColor = action.payload
        },
        setLinkLanguage: (state, action) => {
            state.link.language = action.payload
        },
        setLinkFont: (state, action) => {
            state.link.font = action.payload
        },
        setLinkBorder: (state, action) => {
            state.link.border = action.payload
        },
        setLinkGreeting: (state, action) => {
            state.link.greeting = action.payload
        },
        setLinkMessage: (state, action) => {
            state.link.message = action.payload
        },
        setLinkOtherCurrency: (state, action) => {
            state.link.otherCurrency = action.payload
        },
        setLinkAdd: (state, action) => {
            state.link.add = action.payload
        },
        setGatewayButtonColor: (state, action) => {
            state.gateway.buttonColor = action.payload
        },
        setGatewayLanguage: (state, action) => {
            state.gateway.language = action.payload
        },
        setGatewayFont: (state, action) => {
            state.gateway.font = action.payload
        },
        setGatewayBorder: (state, action) => {
            state.gateway.border = action.payload
        },
        setGatewayOtherCurrency: (state, action) => {
            state.gateway.otherCurrency = action.payload
        },
        setGatewayAdd: (state, action) => {
            state.gateway.add = action.payload
        },
        setLinkFullProduct: (state, action) => state.link = action.payload,
        setGatewayFullProduct: (state, action) => state.gateway = action.payload,
        resetState: () => initialState,
    }
});

export const { 
    setLinkBackgroundColor,
    setLinkBorder,
    setLinkButtonColor,
    setLinkFont,
    setLinkFullProduct,
    setLinkGreeting,
    setLinkLanguage,
    setLinkMessage,
    setLinkOtherCurrency,
    setLinkAdd,
    setGatewayBorder,
    setGatewayButtonColor,
    setGatewayFont,
    setGatewayFullProduct,
    setGatewayLanguage,
    setGatewayOtherCurrency,
    setGatewayAdd,
    resetState
} = serviceSlice.actions

export default serviceSlice.reducer